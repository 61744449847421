// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/InterTight-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Inter Tight';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-weight: normal; /* You can specify the font weight if it's a specific weight */
  font-style: normal; /* You can specify the style if it's italic or normal */
}

.hero-text {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 1000;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.hero-text2 {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 1000;
  text-transform: none;
  letter-spacing: 0.04em;
}

.content-text {
  font-family: 'Inter Tight', sans-serif;
  text-align: start;
  font-weight: 900;
  text-transform: none;
  letter-spacing: normal;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,+DAAuE;EACvE,mBAAmB,EAAE,8DAA8D;EACnF,kBAAkB,EAAE,uDAAuD;AAC7E;;AAEA;EACE,sCAAsC;EACtC,iBAAiB;EACjB,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;EACE,sCAAsC;EACtC,iBAAiB;EACjB,oBAAoB;EACpB,sBAAsB;AACxB;;AAEA;EACE,sCAAsC;EACtC,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;EACpB,sBAAsB;AACxB","sourcesContent":["@font-face {\n  font-family: 'Inter Tight';\n  src: url('./fonts/InterTight-VariableFont_wght.ttf') format('truetype');\n  font-weight: normal; /* You can specify the font weight if it's a specific weight */\n  font-style: normal; /* You can specify the style if it's italic or normal */\n}\n\n.hero-text {\n  font-family: 'Inter Tight', sans-serif;\n  font-weight: 1000;\n  text-transform: uppercase;\n  letter-spacing: 0.04em;\n}\n\n.hero-text2 {\n  font-family: 'Inter Tight', sans-serif;\n  font-weight: 1000;\n  text-transform: none;\n  letter-spacing: 0.04em;\n}\n\n.content-text {\n  font-family: 'Inter Tight', sans-serif;\n  text-align: start;\n  font-weight: 900;\n  text-transform: none;\n  letter-spacing: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
